<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid dashboard" style="position: relative" v-if="!showloaderpage">
                <div class="p-col-12 p-md-12">
                    <div class="card widget-social">
                        <div class="p-d-flex">
                            <span class="subtext p-mt-2 p-text-capitalize">
                                <h6>Admin Name : {{ adminList.maa2 ? adminList.maa2 : '-' }} {{ adminList.maa4 ?
                                    `(${adminList.maa4})` : '' }} </h6>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientInfo()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-id-card" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Admin Info
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientConfig()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-globe" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Admin Config
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientPlan()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-ticket" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Admin Plan
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="editClientAddFund()" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-wallet" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Add Fund
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="clientRole" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-tag" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Admin Role
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="clientDocument" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-file" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Admin Document
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="serviceSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-cog" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Services Setting
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="addDebit" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-credit-card" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Add Debit
                        </template>
                    </Card>
                </div>

                <!-- <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WhatsAppSettings" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-whatsapp" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            WhatsApp Settings
                        </template>
                    </Card>
                </div>  -->
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="TemplateActions" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-envelope" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Template Actions
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WhatsAppTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-book" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            WhatsApp Template Settings
                        </template>
                    </Card>
                </div>
            
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="RCSTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-comment" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            RCS Template Settings
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WebBotTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-book" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Web Bot Template Settings
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="CriticalPermissions" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-lock" style="font-size: 2rem"></i>
                            <Divider />
                        </template>

                        <template #content>
                            Critical Permissions
                        </template>
                    </Card>
                </div>

            </div>
            <div v-if="showloaderpage" style="height: 544px; position: relative">
                <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import router from '@/router';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            adminList: [],
            clientname: '',
            showloaderpage: false,
            routeParam: '',
            localAdminName: '',
            adminnamevalue: '',
        };
    },

    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminId;
        this.getAdminUserDetails({ maa1: this.routeParam });
        if (localStorage.localAdminName) {
            this.localAdminName = localStorage.localAdminName;
        }
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        getAdminUserDetails(ev) {
            this.showloaderpage = true;
            this.ApiService.getAdminUserDetails(ev).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                    this.showloaderpage = false;
                    localStorage.localAdminName = this.adminList.maa2;
                } else {
                    this.adminList = '';
                    this.showloaderpage = false;
                }
            });
        },
        editClientInfo() {
            router.push({
                name: "admininfo",
                params: { adminSubId: this.routeParam },

            });
        },
        editClientConfig() {
            router.push({
                name: "adminconfig",
                params: { adminSubId: this.routeParam },

            });
        },
        editClientPlan() {
            router.push({
                name: "adminplan",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        editClientAddFund() {
            router.push({
                name: "adminaddfund",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        clientRole() {
            router.push({
                name: "adminrole",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        clientDocument() {
            router.push({
                name: "admindocument",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        serviceSetting() {
            router.push({
                name: "service-setting",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        addDebit() {
            router.push({
                name: "add-debit",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        WhatsAppSettings() {
            router.push({
                name: "whatsapp-settings",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        WhatsAppTemplateSetting() {
            router.push({
                name: "whatsapp-template-settings",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        WebBotTemplateSetting() {
            router.push({
                name: "web-bot-template-settings",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        TemplateActions() {
            router.push({
                name: "template-actions",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        RCSTemplateSetting() {
            router.push({
                name: "RCS-template-settings",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },

            });
        },
        CriticalPermissions() {
            router.push({
                name: "critical-permissions",
                params: { adminSubId: this.routeParam, adminnamevalue: this.adminList.maa2 },
            });
        }
    },
};
</script>
    

<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

.dashboard-card:hover {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 10px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    cursor: pointer;
    color: #3F51B5;
}
</style>
    